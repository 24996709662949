<template>
  <div class="container px-sm-0 bg-light">
    <div class="row p-sm-5 pt-3 pb-3">
      <div class="col">
        <div class="alert text-center" role="alert" v-if="notSubmited">
          <div class="m-auto icon-folder">
            <img src="../assets/img/folder.png" alt="" class="img-fluid" />
          </div>
          <h3>Dossier non soumis.</h3>
        </div>
        <div class="alert text-center" role="alert" v-if="pending">
          <!-- <img src="../assets/img/gear.svg" alt="" />
          <h3>Votre dossier est en cours de traitement.</h3>
          Revenez régulièrement vérifier la suite de votre candidature et/ou
          vérifier régulièrement votre boite mail. -->
          <h3>Le traitement des dossiers est finalisé.</h3>
          <p>Les résultats des candidatures sont disponibles sur la page Facebook de Bénin Excellence ou sur la page LinkedIn de l'EEIA.</p>
          <p><a href="https://www.facebook.com/share/p/zyz8uAMViP7xqnZF/">Cliquez ici pour accéder à la publication sur Facebook</a></p>
          <p><a href="https://www.linkedin.com/posts/%C3%A9cole-d-%C3%A9t%C3%A9-sur-l-intelligence-artificielle_liste-definitive-laureats-eeia-eeia-juniors-activity-7218172996581027840-yE4D?utm_source=share&utm_medium=member_desktop">Cliquez ici pour accéder à la publication sur LinkedIn</a></p>
        </div>
        <div class="alert text-center" role="alert" v-if="preselected">
          <h1 class="congrat-title">Présélectionné (e):</h1>
          Bientôt les résultats
        </div>
        <div class="alert text-center" role="alert" v-if="selected">
          <img src="../assets/img/trophy2.svg" alt="" />
          <h1 class="congrat-title">Félicitations, vous êtes sélectionné.</h1>
          <!-- Passez dans l'une de nos bibliothèques ou appelez le
          <a href="tel:+229 91 05 15 15">+229 91 05 15 15</a> /
          <a href="tel:+229 91 13 87 87">+229 91 13 87 87</a>. -->
        </div>
        <div class="alert text-center" role="alert" v-if="eliminated">
          <h4 class="mt-4">
            Votre candidature n’a pas abouti, nous vous remercions pour la
            confiance et vous invitons à réessayer l'année prochaine.
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const confetti = require("canvas-confetti").default;
var end = Date.now() + 15 * 600;

// go Buckeyes!
var colors = ["#0066b6", "#ffffff"];

export default {
  name: "Status",
  mounted() {
    this.getUser();
  },
  data() {
    return {
      notSubmited: false,
      pending: false,
      preselected: false,
      selected: false,
      eliminated: false,
    };
  },
  components: {},
  methods: {
    getUser() {
      this.$http.get("users/000").then((result) => {
        // if (!result.data.userProfile.email_verified) {
        //   this.$router.push({ name: "EmailVerification" });
        // }

        localStorage.setItem("candidature_step", result.data.step);
        this.notSubmited = result.data.step < 6 && result.data.step >= 0;
        this.pending = result.data.step == 6;
        this.preselected = result.data.step == 7;
        this.selected = result.data.step == 8;
        this.eliminated = result.data.step == -1;

        if (this.selected) {
          this.frame()
        }

      });
    },
    frame() {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors,
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors,
      });

      if (Date.now() < end) {
        requestAnimationFrame(this.frame);
      }
    },
  },
  computed: {
    showRefresh() {
      if (this.selected || this.eliminated) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.icon-folder {
  width: 25% !important;
}

@media (max-width: 575.98px) {
  .icon-folder {
    width: 50% !important;
  }
}
</style>
