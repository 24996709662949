<template>
  <div class="container px-sm-0 bg-light">
    <div class="row p-sm-5 pt-3">
      <div class="col">
        <div class="alert alert-info fs-3" role="alert" v-if="newSession">
          <span class="welcom-text"></span>
        </div>
        <div class="alert alert-info fs-3" role="alert" v-else>
          Bienvenue sur la plateforme de candidature à l'EEIA 2024!
        </div>
        <div class="alert alert-danger fs-3 text-center" role="alert" v-if="deadlinePassed">
          Les candidatures sont closes.
        </div>
        <div class="col mt-5 mb-4">
          <h4 class="section-title text-uppercase">SECTION INFO</h4>
        </div>
        <div
          class="card border-info mb-3"
          v-for="info in informations"
          :key="info.id"
        >
          <div class="card-body ql-editor">
            <p class="card-text" v-html="info.content"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Typed from "typed.js";

export default {
  name: "Home",
  inject: ["ToastSuccess", "ToastError", "currentYearUrl"],
  mounted() {
    if (localStorage.getItem("candidature_step")) {
      this.preselected = localStorage.getItem("candidature_step") == 4;
      this.selected = localStorage.getItem("candidature_step") == 5;
    }

    this.getInformations();
    this.getUser();
    this.checkDeadline();
  },
  data() {
    return {
      informations: {},
      newSession: this.$store.state.newSession,
      deadlinePassed: false,
    };
  },
  methods: {
    getInformations() {
      this.$http.get("announcements").then((result) => {
        this.informations = result.data;
      });
    },
    getUser() {
      this.$http.get("users/000").then((result) => {
        // redirect to /verify-email if result.data.email_verified == false
        // if (!result.data.email_verified) {
        //   this.$router.push({ name: "EmailVerification" });
        // }

        localStorage.setItem("candidature_step", result.data.step);

        if (this.newSession) {
          this.$store.commit("updateSession");
          var options = {
            strings: [
              `Bonjour ${result.data.first_name}.`,
              "Bienvenue sur la plateforme de candidature à l'EEIA 2024!",
            ],
            typeSpeed: 40,
            startDelay: 1005,
            backDelay: 1005,
            backSpeed: 40,
          };

          new Typed(".welcom-text", options);

          sessionStorage.setItem("new_session", "false");
        }
      });
    },
    checkDeadline() {
      fetch(this.currentYearUrl.replace('current-year', 'deadline'))
      .then(response => {
        if (!response.ok) {
          return { deadlinePassed: false };
        }
        return response.json();
      })
      .then(data => {
        this.deadlinePassed = data?.deadlinePassed;
      })
    },
  },
};
</script>

<style scoped>
@import "../assets/styles/quill.core.css";
.container {
  background-color: #80808096;
  /* min-height: 50vh; */
}

.section-title {
  border-bottom: 3px solid #3490dc;
}
</style>
