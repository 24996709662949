<style scoped>
footer {
  background-color: #a9beb696;
}
footer {
  background-image: url("../assets/img/bg-navbar.jpg");
}
</style>

<template>
  <div class="container text-white px-0">
    <footer class="mt-0 pt-1 pb-1 px-3 text-center text-small">
      <ul class="list-unstyled">
        <li class="mt-2">
          &copy; Fondation Vallet - Bénin Excellence <br>
          2021–<span id="currentYear">2022</span> 
          <!-- | v2.0 -->
        </li>
        <li>
          <router-link :to="{ name: 'Contact' }" class="footer-link text-decoration-underline">
            Nous contacter
          </router-link>
        </li>
      </ul>
    </footer>
  </div>
</template>

<script>
export default {
  inject: ["currentYearUrl"],
  mounted() {
    fetch(this.currentYearUrl)
      .then((result) => {
        result.text().then(function (text) {
          document.getElementById("currentYear").textContent = text;
        });
      })
      .catch(() => {
        document.getElementById("currentYear").textContent = "2022";
      });
  },
  /*
   * The component's data.
   */
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>
.footer-link {
  text-decoration: none;
  color: white !important;
}

.footer-link:hover {
  text-decoration: underline;
}
</style>
