import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
// import Preselection from '../views/Preselection.vue'
// import Selection from '../views/Selection.vue'
import Status from '../views/Status.vue'
import EmailVerification from '../views/EmailVerification.vue'
import PasswordForget from '../views/PasswordForget.vue'
import Navbar from '../components/TheNavbar.vue'
import Footer from '../components/TheFooter.vue'
import NotFound from '../components/The404Page.vue'
import Evaluation from '../components/Evaluation.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      navbar: Navbar,
      main: Home,
      footer: Footer
    },
    meta: {
      requiresAuth: true,
      frenchName: 'Accueil'
    }
  },
  // {
  //   path: '/preselection',
  //   name: 'Preselection',
  //   components: {
  //     navbar: Navbar,
  //     main: Preselection,
  //     footer: Footer
  //   },
  //   meta: {
  //     requiresAuth: true,
  //     frenchName: 'Suis-je préselectionné ?'
  //   }
  // },
  // {
  //   path: '/selection',
  //   name: 'Selection',
  //   components: {
  //     navbar: Navbar,
  //     main: Selection,
  //     footer: Footer
  //   },
  //   meta: {
  //     requiresAuth: true,
  //     frenchName: 'Suis-je sélectionné ?'
  //   }
  // },
  {
    path: '/status',
    name: 'Status',
    components: {
      navbar: Navbar,
      main: Status,
      footer: Footer
    },
    meta: {
      requiresAuth: true,
      frenchName: 'Statut'
    }
  },
  {
    path: '/register',
    name: 'Register',
    components: {
      main: Register,
    },
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/evaluation',
    name: 'evaluation',
    components: {
      navbar: Navbar,
      main: Evaluation,
      footer: Footer,
    },
  },
  {
    path: '/login',
    name: 'Login',
    components: {
      main: Login,
    },
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/verify-email',
    name: 'EmailVerification',
    components: {
      main: EmailVerification,
    },
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/forget-password',
    name: 'PasswordForget',
    components: {
      main: PasswordForget,
    },
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/reset-password',
    name: 'PasswordReset',
    components: {
      main: () => import(/* webpackChunkName: "about" */ '../views/PasswordReset.vue'),
    },
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/cgu',
    name: 'CGU',
    components: {
      main: () => import(/* webpackChunkName: "about" */ '../views/CGU.vue'),
    },
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/new-candidacy',
    name: 'NewCandidacy',
    components: {
      navbar: () => import(/* webpackChunkName: "about" */ '../components/TheNavbar.vue'),
      main: () => import(/* webpackChunkName: "about" */ '../views/NewCandidacy.vue'),
      footer: () => import(/* webpackChunkName: "about" */ '../components/TheFooter.vue')
    },
    meta: {
      requiresAuth: true,
      frenchName: 'Nouvelle candidature'
    }
  },
  {
    path: '/edit-candidacy',
    name: 'EditCandidacy',
    components: {
      navbar: () => import(/* webpackChunkName: "about" */ '../components/TheNavbar.vue'),
      main: () => import(/* webpackChunkName: "about" */ '../views/NewCandidacy.vue'),
      footer: () => import(/* webpackChunkName: "about" */ '../components/TheFooter.vue')
    },
    meta: {
      requiresAuth: true,
      frenchName: 'Modifier ma candidature'
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    components: {
      navbar: () => import(/* webpackChunkName: "about" */ '../components/TheNavbar.vue'),
      main: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue'),
      footer: () => import(/* webpackChunkName: "about" */ '../components/TheFooter.vue')
    },
    meta: {
      requiresAuth: true,
      frenchName: 'Mon profil'
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    components: {
      navbar: () => import(/* webpackChunkName: "about" */ '../components/TheNavbar.vue'),
      main: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue'),
      footer: () => import(/* webpackChunkName: "about" */ '../components/TheFooter.vue')
    },
    meta: {
      requiresAuth: true,
      frenchName: 'Nous contacter'
    }
  },
  {
    path: "/:catchAll(.*)",
    name: 'Not-Found',
    components: {
      main: NotFound,
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { left: 0, top: 0 }
  },
})

export default router
